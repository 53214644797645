import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
							Open Source Projects
						</div>

						<div className="subtitle projects-subtitle">
							I have garnered extensive experience working on
							multiple projects, each involving diverse
							technology stacks, over the course of my career.
							This varied exposure has equipped me with a robust
							skill set and a comprehensive understanding of
							different technologies, making me well-equipped to
							tackle multifaceted challenges in the field.
						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					{/*<div className="page-footer">*/}
					{/*	<Footer />*/}
					{/*</div>*/}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;